<template>
  <div class="home">
    <div class="loginBox">
      <router-link to="/">
        <img src="@/assets/images/logo.png" alt="logo" />
      </router-link>
      <h2>LOGIN</h2>
      <div class="form">
        <input type="text" v-model="email" placeholder="이메일" />
        <div class="password">
          <input :type="type" v-model="password" @keyup.enter="submit" placeholder="비밀번호" />
        </div>
        <div v-if="error" class="error">가입하지 않은 이메일 혹은 잘못된 비밀번호입니다.</div>
        <button class="point large" @click="submit">로그인</button>
        <!-- <router-link to="/join">
            <button class="large">회원가입</button>
        </router-link>-->
      </div>

      <aside class="flexL">
        <div>
          <router-link to="/join">회원가입</router-link>
          <router-link to="/findEmail">아이디찾기</router-link>
          <router-link to="/findPassword">비밀번호찾기</router-link>
        </div>
      </aside>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { login } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      pwOn: false,
      type: "password",
      email: "",
      password: "",
      error: false,
    };
  },
  methods: {
    submit() {
      if (this.email == "") {
        return alert("이름을 입력해주세요.");
      } else if (this.emailValidate(this.email) == false) {
         return alert("이메일 형식이 맞지 않습니다.아래와 같이 이메일 주소 전체를 입력해주세요. service@ulmani.co.kr");
      } else if (this.password == "") {
        return alert("비밀번호를 입력해주세요.");
      }
      let data = {
        email: this.email,
        password: this.password,
      };
      login(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("LOGIN", res.data.accesstoken);
          document.location.href = "/";
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
